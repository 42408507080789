import { Button } from "@yolaw/ui-kit-components";
import { useEffect, useReducer, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { useFluidLayout, useIsMobile, useLegalEntity } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import { Sidebar } from "layout";
import { LoadingPage } from "pages/loading";
import { ELEMENT_IDS } from "pages/zen/constants";
import { BuiltRoutePath } from "services/router";
import DecisionsContext from "../context";
import useDecision from "../hooks/useDecision";
import useDecisionsContext from "../hooks/useDecisionsContext";
import CertificateAndExportRegistriesModal from "../modals/CertificateAndExportRegistriesModal";
import DecisionDeleteModal from "../modals/DecisionDeleteModal";
import DecisionEditModal from "../modals/DecisionEditModal";
import DocumentDeleteModal from "../modals/DocumentDeleteModal";
import DocumentRenameModal from "../modals/DocumentRenameModal";
import DecisionDetails from "./DecisionDetails";
import DocumentView from "./DocumentView";
import NoDocumentView from "./NoDocumentView";
import AddDocument, { AddDocumentPaths } from "./add-document";
import BottomStickyBar from "./components/BottomStickyBar";
import DecisionViewContext from "./context";
import useDecisionViewContext from "./hooks/useDecisionViewContext";
import useDocument from "./hooks/useDocument";

const DESKTOP_SIDEBAR_WIDTH = 500;

const DesktopLayoutContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-areas: "sidebar main-content";
  grid-template-columns: ${DESKTOP_SIDEBAR_WIDTH}px 1fr;

  ${({ theme }) => css`
    .sidebar-container {
      box-shadow: ${theme.shadows.blue.medium};
      z-index: 1;
      overflow: hidden;

      > .sidebar {
        z-index: unset;
        height: 100%;
      }
    }

    .document-view-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  `}
`;

type DecisionViewBottomStickyBarProps = {
  onAddDocumentClick?: VoidFunction;
};
const DecisionViewBottomStickyBar = ({
  onAddDocumentClick,
}: DecisionViewBottomStickyBarProps) => {
  const decision = useDecision();
  const { setCurrentDocumentId } = useDecisionViewContext();
  const decisionsContext = useDecisionsContext();
  const currentLegalEntity = useLegalEntity();
  const { navigateTo } = useAppRoutes();

  const startDocumentAddingProcess = () => {
    // Desktop: remove the current document => the add document screen will display
    setCurrentDocumentId(null);
    // Mobile might needs additional action
    onAddDocumentClick?.();
  };

  const openCertificateAndExportRegistriesModal = () => {
    if (currentLegalEntity.siren) {
      decisionsContext.openModal({
        id: DecisionsContext.DecisionModal.CertificateAndExportRegistries,
      });
    } else {
      // If the Siren is not ready, user should not be able to finalize his decision or export it.
      navigateTo(BuiltRoutePath.MandatoryRecordsPage);
    }
  };

  return (
    <BottomStickyBar id={ELEMENT_IDS.STICKY_BOTTOM_BAR}>
      <Button variant="secondary" onClick={startDocumentAddingProcess}>
        Ajouter un autre document
      </Button>
      {!decision?.finalized && (
        <Button onClick={openCertificateAndExportRegistriesModal}>
          Finaliser
        </Button>
      )}
    </BottomStickyBar>
  );
};

const DesktopLayout = () => {
  const { currentDocumentId } = useDecisionViewContext();
  const currentDocument = useDocument(currentDocumentId || 0);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentDocumentId) {
      navigate(`document/${currentDocumentId}`);
    } else {
      navigate(".");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocumentId]);

  return (
    <DesktopLayoutContainer>
      <div className="sidebar-container">
        <Sidebar open>
          <DecisionDetails />
        </Sidebar>
      </div>
      <div className="document-view-container">
        <Routes>
          <Route index element={<NoDocumentView />} />
          <Route
            path={"document/:documentId"}
            element={
              <>
                <DocumentView url={currentDocument?.file_url || ""} />
                <DecisionViewBottomStickyBar />
              </>
            }
          />
          <Route
            path={`${AddDocumentPaths.index}/*`}
            element={<AddDocument />}
          />
        </Routes>
      </div>
    </DesktopLayoutContainer>
  );
};

const MobileLayoutContainer = styled.div<{ $isAddingDocument: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  > .decision_details_container {
    flex: 1;
    ${({ $isAddingDocument }) =>
      $isAddingDocument &&
      css`
        flex: unset;
      `}
  }
`;

const MobileLayout = () => {
  const decision = useDecision();
  const { currentDocumentId } = useDecisionViewContext();
  const [isAddingDocument, setIsAddingDocument] = useState(false);

  useEffect(() => {
    if (currentDocumentId) {
      // Trick to re display the document list after uploading another document
      setIsAddingDocument(false);
    }
  }, [currentDocumentId]);

  return (
    <Routes>
      <Route
        index
        element={
          <MobileLayoutContainer $isAddingDocument={isAddingDocument}>
            <DecisionDetails hideDocuments={isAddingDocument} />
            {decision?.documents.length && !isAddingDocument ? (
              <DecisionViewBottomStickyBar
                onAddDocumentClick={() => setIsAddingDocument(true)}
              />
            ) : (
              <NoDocumentView />
            )}
          </MobileLayoutContainer>
        }
      />
      <Route path={`${AddDocumentPaths.index}/*`} element={<AddDocument />} />
    </Routes>
  );
};

export const DecisionView = () => {
  useFluidLayout();
  const isMobile = useIsMobile();
  const decision = useDecision();

  const [state, dispatch] = useReducer(DecisionViewContext.reducer, {
    ...DecisionViewContext.initialState,
    // Pick the first document if it's available by default
    currentDocumentId: decision?.visibleDocuments[0]?.id || null,
  });
  const decisionsContext = useDecisionsContext();

  // If the decisions hasn't been loaded
  // show loading page and wait
  if (!decisionsContext.loaded) return <LoadingPage />;

  // If the decisions has been loaded but the decision (ID in URL) is not found
  // Get back to the decision list page
  if (decisionsContext.loaded && !decision)
    return <Navigate to={".."} relative="path" />;

  // All set
  return (
    <DecisionViewContext.Context.Provider value={{ state, dispatch }}>
      {isMobile ? <MobileLayout /> : <DesktopLayout />}

      <DocumentRenameModal
        open={
          state.openingModal?.id ===
          DecisionViewContext.DocumentModal.DocumentRename
        }
      />
      <DocumentDeleteModal
        open={
          state.openingModal?.id ===
          DecisionViewContext.DocumentModal.DocumentDelete
        }
      />
      <DecisionEditModal
        isOpen={
          decisionsContext.openingModal?.id ===
          DecisionsContext.DecisionModal.DecisionEdit
        }
      />
      <DecisionDeleteModal
        isOpen={
          decisionsContext.openingModal?.id ===
          DecisionsContext.DecisionModal.DecisionDelete
        }
      />
      <CertificateAndExportRegistriesModal
        open={
          decisionsContext.openingModal?.id ===
          DecisionsContext.DecisionModal.CertificateAndExportRegistries
        }
      />
    </DecisionViewContext.Context.Provider>
  );
};

export default DecisionView;
