import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import ChatButton from "../components/ChatButton";

/** Display the ChatButton (currently Zendesk Chat) at the bottom right of page */
const LegalAssistantAvailableArea = () => {
  /** As of Nov 2024, we disable Zendesk Chat and prepare another 3rd-party
   * integration: Intercom.
   * @see https://app.clickup.com/t/2571097/BIZTRIBE-1880
   */
  const shouldDisplayChatButton = false;
  // const project = useZenProject();
  // const shouldDisplayChatButton = !!project.info.zen_subscription_status;

  useEffect(() => {
    if (shouldDisplayChatButton) {
      // Show the chat button
      window.$zopim?.livechat.button.show();
    } else {
      // Ensure the chat is hidden
      window.$zopim?.livechat.hideAll();
    }

    return () => {
      // Hide the chat when moving out of the support area
      window.$zopim?.livechat.hideAll();
    };
  }, [shouldDisplayChatButton]);

  return (
    <>
      <Outlet />
      {shouldDisplayChatButton && <ChatButton />}
    </>
  );
};

export default LegalAssistantAvailableArea;
