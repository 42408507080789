import ApiService from "services/api";
import { getLegalEntityIdInURL } from "services/router";
import { LegalEntity } from "types/legal-entities";
import { Subscription, SubscriptionFamilySlug } from "types/subscriptions";

export const getLegalEntities = async () => {
  try {
    const response = await ApiService.legalEntities.getLegalEntities();
    if (response?.status === 200) {
      return response.data;
    }
    return [];
  } catch (error) {
    throw error;
  }
};

/** Find the most recent created LE has linked to LSCP
 * @returns LegalEntity | undefined
 */
export const findLatestLEHasLSCP = (
  legalEntities: LegalEntity[],
  subscriptions: Subscription[]
): LegalEntity | undefined => {
  const latestLEIDHasLSCP = (
    subscriptions
      .map(
        (subscription) =>
          subscription.family.slug ===
            SubscriptionFamilySlug.LSBusinessCapitalDeposit &&
          subscription.legal_entity_id
      )
      .filter(Boolean) as number[]
  ) // take the LE IDs from LSCP subs
    .sort((a, b) => a - b) // ASC <=> oldest > newest
    .pop(); // take the last one, most recent created LE ID

  return legalEntities.find(
    (le) => String(le.id) === String(latestLEIDHasLSCP)
  );
};

export const getDefaultLegalEntityID = (
  legalEntities: LegalEntity[],
  subscriptions: Subscription[]
): LegalEntity["id"] | null => {
  // 1️⃣ Try to find a LE that match the given ID in URL
  const legalEntityIdInURL = getLegalEntityIdInURL();
  if (legalEntityIdInURL) {
    const foundLE = legalEntities.find(
      (le) => String(le.id) === legalEntityIdInURL
    );
    if (foundLE) return foundLE.id;
  }

  // 2️⃣ Find the most recent created LE has linked to LSCP
  const latestLEHasLSCP = findLatestLEHasLSCP(legalEntities, subscriptions);
  if (latestLEHasLSCP) {
    return latestLEHasLSCP.id;
  }

  // 3️⃣ Otherwise, take the first LE in the LE list
  return legalEntities[0]?.id || null;
};

export const getLegalEntityDetails = async (
  legalEntityId: LegalEntity["id"]
) => {
  try {
    const response = await ApiService.legalEntities.getLegalEntityDetails(
      legalEntityId
    );

    if (response?.data) {
      return response.data;
    }

    throw new Error("Failed to get Legal entity details");
  } catch (error) {
    throw error;
  }
};

export const getCapitalDeposit = async (legalEntityId: LegalEntity["id"]) => {
  try {
    const response = await ApiService.legalEntities.getCapitalDeposit(
      legalEntityId
    );

    return response?.data;
  } catch (error) {
    throw error;
  }
};
