import { FC, ReactElement, useContext } from "react";
import { LinkProps, NavLink } from "react-router-dom";
import { styled } from "styled-components";

import { LayoutContext } from "../../contexts";
import { useIsMobile } from "../../hooks";
import { getNavigationLinkBaseStyles, NavigationLinkLabel } from "./helper";

const NavigationLink = styled((props: LinkProps) => <NavLink {...props} />)`
  ${getNavigationLinkBaseStyles()};
`;

const NavigationButton = styled.span`
  ${getNavigationLinkBaseStyles()};
`;

const NavigationLinkIcon = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  width: var(--nav-item-icon-width);
  height: 24px;

  > svg {
    margin: auto;
  }
`;

const getNavLinkClassName = ({
  isActive,
  isPending,
}: {
  isActive: boolean;
  isPending: boolean;
}) => (isActive ? "active" : isPending ? "pending" : "");

type BaseNavItemProps = {
  label: string | ReactElement;
  icon?: JSX.Element;
  className?: string;
};

const BaseNavItem: FC<BaseNavItemProps> = ({ icon, label }) => {
  return (
    <>
      {icon && <NavigationLinkIcon>{icon}</NavigationLinkIcon>}
      <NavigationLinkLabel>{label}</NavigationLinkLabel>
    </>
  );
};

export type NavItemProps = BaseNavItemProps & {
  path?: string;
  onClick?: VoidFunction;
};

const NavigationItem: FC<NavItemProps> = ({
  className,
  onClick,
  path,
  ...baseProps
}) => {
  const { dispatch: layoutDispatch } = useContext(LayoutContext.Context);
  const isMobile = useIsMobile();

  const onNavigationLinkClick = () => {
    if (onClick) onClick();
    if (isMobile) {
      layoutDispatch({ type: "CLOSE_SIDEBAR" });
      document.body.style.overflow = "unset";
    }
  };

  return (
    <li className={className}>
      {path ? (
        <NavigationLink
          to={path}
          onClick={onNavigationLinkClick}
          className={`nav-item-link ${getNavLinkClassName}`}
        >
          <BaseNavItem {...baseProps} />
        </NavigationLink>
      ) : (
        <NavigationButton onClick={onNavigationLinkClick}>
          <BaseNavItem {...baseProps} />
        </NavigationButton>
      )}
    </li>
  );
};

export default NavigationItem;
