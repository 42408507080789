import { FC, memo } from "react";
import styled from "styled-components";

type StyledSidebarProps = {
  $isOpen?: boolean;
};

const StyledSidebar = styled.div<StyledSidebarProps>`
  z-index: 4000;
  grid-row: sidebar-start / sidebar-end;
  background-color: ${(props) => props.theme.colors.common.white};
  box-shadow: ${(props) => props.theme.shadows.blue.medium};
  translate: ${(props) => (props.$isOpen ? 0 : "-105%")};
  transition: translate 0.3s ease;
  overflow-y: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-column: sidebar-start / main-content-end;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-column: sidebar-start / sidebar-end;
  }
`;

type SidebarProps = {
  open?: boolean;
} & React.PropsWithChildren;

export const Sidebar: FC<SidebarProps> = memo(({ open, children }) => {
  return (
    <StyledSidebar $isOpen={open} className="sidebar">
      {children}
    </StyledSidebar>
  );
});
