import { Tag } from "@yolaw/ui-kit-components";
import { ChevronDownFlat, UniversalBuilding } from "@yolaw/ui-kit-icons";
import { FC } from "react";
import { css, styled } from "styled-components";

import { useLegalEntity } from "hooks";
import LegalEntityBaseButton from "./LegalEntityBaseButton";

type ContainerProps = {
  $isOpen: boolean;
};

const Container = styled(LegalEntityBaseButton)<ContainerProps>`
  ${({ theme, $isOpen }) => css`
    border: 1px solid ${theme.colors.neutral.lighter};
    border-radius: ${theme.borderRadius.s}px;

    ${$isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

    @media (max-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.xxs}px ${theme.spacing.xxxs}px;
    }

    @media (min-width: ${theme.breakpoints.m + 1}px) {
      padding: ${theme.spacing.xxxxs}px ${theme.spacing.xxxs}px;
    }
  `};
`;

type ArrowIconProps = {
  $isOpen: boolean;
};

const ArrowIcon = styled.span<ArrowIconProps>`
  display: inline-block;
  margin-left: auto;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      rotate: 180deg;
    `}
`;

type LegalEntitySwitchHeaderProps = {
  $isOpen: boolean;
  onClick: () => void;
};

const LegalEntitySwitchHeader: FC<LegalEntitySwitchHeaderProps> = ({
  $isOpen,
  onClick,
}) => {
  const currentLegalEntity = useLegalEntity();

  return (
    <Container $isOpen={$isOpen} onClick={onClick}>
      <UniversalBuilding size="24" />
      <div className="le_selector-label_container">
        <span className="le_selector-le_name_label" translate="no">
          {currentLegalEntity.name}
        </span>
        {currentLegalEntity.lsCompteProAccessibleSubscription && (
          <Tag color="blue" className="le_selector-le_tag">
            Compte Pro
          </Tag>
        )}
      </div>
      <ArrowIcon $isOpen={$isOpen}>
        <ChevronDownFlat size="16" />
      </ArrowIcon>
    </Container>
  );
};

export default LegalEntitySwitchHeader;
