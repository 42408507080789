import {
  CapitalDeposit,
  LegalEntity,
  LegalEntityDetails,
} from "types/legal-entities";
import coreApiInstance from "../coreApi";

export const getLegalEntities = () =>
  coreApiInstance.get<Array<LegalEntity>>("/my/legal-entities/");

export const getLegalEntityDetails = (legalEntityId: LegalEntity["id"]) =>
  coreApiInstance.get<LegalEntityDetails>(
    `/my/legal-entities/${legalEntityId}/`
  );

export const getCapitalDeposit = (legalEntityId: LegalEntity["id"]) =>
  coreApiInstance.get<CapitalDeposit>(
    `/legal-entities/${legalEntityId}/capital-deposit/me/`
  );
