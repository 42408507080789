import styled, { css } from "styled-components";

const AddDocumentPageContainer = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;

    .page-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      row-gap: ${theme.spacing.s}px;

      padding: ${theme.spacing.xs}px ${theme.spacing.s}px ${theme.spacing.s}px
        ${theme.spacing.s}px;

      @media (min-width: ${theme.breakpoints.l}px) {
        padding: ${theme.spacing.xxxl}px;
      }
    }
  `};
`;

export default AddDocumentPageContainer;
