export enum LegalStructureType {
  Association,
  Commercial,
  RealEstate,
  MicroOrAutoEnterprise,
}

export enum LegalStructure {
  // Association
  Association = "ASSOCIATION",

  // Commercial
  SAS = "SAS",
  SASU = "SASU",
  EURL = "EURL",
  SARL = "SARL",

  // RealEstate
  SCI = "SCI",

  // MicroOrAutoEnterprise
  IndividualEnterprise = "EI",
  AutoEnterprise = "AE",
}

interface LegalEntityOwner {
  email: string;
  first_name: string;
  last_name: string;
}

export interface LegalEntity {
  funnel_id: number | null;
  id: number;
  is_standalone_for_subscription?: boolean | null;
  legal_structure: LegalStructure;
  name: string;
  workflow_id: number | null;
  service_id?: number | null;
  siren?: string | null;
  has_access_to_zen?: boolean | null;
  service_id_for_zen?: number | null;
  owner?: LegalEntityOwner;
  myKDep?: CapitalDeposit;
  compte_pro_active?: boolean;
}

export interface LegalEntityDetails extends LegalEntity {
  owner: LegalEntityOwner;
}

export enum CapitalDepositStep {
  START = "start",
  IDENTITY_VERIFICATION = "identity_verification",
  IDENTITY_VERIFICATION_PENDING = "identity_verification_pending",
  IDENTITY_VERIFICATION_ERROR = "identity_verification_error",
  TRANSFER = "transfer",
  FUNDS_TRANSFERRED = "funds_transferred",
  DOCUMENT_VERIFICATION = "document_verification",
  DOCUMENT_VERIFICATION_FAILED = "document_verification_failed",
  CERTIFICATE = "certificate",
  DONE = "done",
  ACCOUNT_ACTIVATED = "account_activated",
}

export interface CapitalDeposit {
  id: number;
  capital_deposit_id: number;
  step: CapitalDepositStep;
  amount: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  error_code?: string;
  iban?: string;
  redirection_url?: string;
}
